import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Fragment, memo } from 'react';
import { clas } from '~/utils/clas';
import { __DEV__ } from '~/utils/__DEV__';
export const AppLinkClasses = {
    Root: styled.div ``.withComponent('a'),
    status: {
        Active: styled.div ``,
    },
};
/**
 * # @deprecated 請參考 {@link AppLink2}
 *
 * @deprecated
 */
export const AppLink = memo(function AppLink(props) {
    const router = useRouter();
    const isOutsideLink = props.href.startsWith('http');
    if (__DEV__ && props.href === '/') {
        throw new Error(`<AppLink href='${props.href}'>, href 必須是 pages/{projectName} 的 {projectName} 開頭。例如要連結到 moneycom 的首頁，應給予 '/moneycom'。`);
    }
    const hrefAs = isOutsideLink
        ? props.href
        : !__DEV__
            ? props.href.replace(/\/([\w\d-]+)\/?/i, '/').replace('//', '/')
            : '';
    return (<Fragment>
      <Link href={props.href} as={hrefAs}>
        <AppLinkClasses.Root className={clas([
            router.asPath === props.href && AppLinkClasses.status.Active,
            props.className,
        ])} css={css `
            ${props.rootCSS}
          `} href={hrefAs || props.href} target={props.target} rel={props.target === '_blank' ? 'noopener noreferrer' : ''}>
          {props.children}
        </AppLinkClasses.Root>
      </Link>
    </Fragment>);
});
